<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <strong>Pekerjaan </strong><small>Edit Data</small>
          </CCardHeader>
          <CCardBody>
            <CForm @submit.prevent="inputPekerjaan">
            <CRow>
              <CCol sm="6">
              <div class="form-group">
              <label> Tahun Anggaran</label>
              <select v-model="tahun_anggaran" placeholder='Pilih Tahun' class="form-control">
                <!-- <option value="" disabled selected>Pilih Tahun</option> -->
                <option value="2022" >2022</option>
                <option value="2021" >2021</option>
                <option value="2020">2020</option>
              </select>
              </div>
              <!-- <CSelect
                label="Tahun Anggaran"
                v-model="tahun_anggaran"
                :options="tahuns"
                placeholder="Pilih Tahun"
              /> -->
              </CCol>
              <CCol sm="6">
              <div class="form-group">
              <label> Nama Kegiatan</label>
              <select v-model="nama_kegiatan" class="form-control">
                <!-- <option value="" disabled selected>Pilih  Kegiatan</option> -->
                <option  v-for="dt in jenis" v-bind:value="dt.nama" v-bind:key="dt.nama" >{{ dt.nama }}</option>
              </select>
              </div>
              <!-- <CSelect
                  label="Nama Kegiatan"
                  v-model="nama_kegiatan"
                  :options="jenis"
                  placeholder="Pilih Kegiatan"
              /> -->
              </CCol>
            </CRow>              
            <CRow>
              <CCol sm="6">
              <div class="form-group">
              <label> Sub Kegiatan</label>
              <select v-model="sub_kegiatan"  class="form-control">
                <!-- <option value="" disabled selected>Pilih Sub Kegiatan</option> -->
                <option  v-for="dt in subjenis" v-bind:value="dt.nama" v-bind:key="dt.nama"  >{{ dt.nama }}</option>
              </select>
              </div>
              <!-- <CSelect
                  v-model="sub_kegiatan"
                  label="Sub Kegiatan"
                  :options="subjenis"
                  placeholder="Pilih Sub Kegiatan"
              /> -->
              </CCol>
              <CCol sm="6">
              <CInput
                v-model="nama_pekerjaan"
              label="Pekerjaan"
              placeholder="Input Nama pekerjaan"
              />
              </CCol>
            </CRow>            
           <CRow>
                <CCol sm="6">
                  <CInput
                    v-model="kontraktor"
                    label="Jasa Penyedia"
                    placeholder="Input nama kontraktor"
                  />
                </CCol>
                <CCol sm="6">
                  <CInput
                    v-model="konsultan_supervisi"
                    label="Konsultan Supervisi"
                    placeholder="Input konsultan supervisi"
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol sm="6">
                  <CInput
                    v-model="nilai_pagu"
                    label="Nilai Pagu"
                    v-bind="money"
                    placeholder="Input nilai pagu"
                  />
                </CCol>
                <CCol sm="6">
                  <CInput
                    v-model="nilai_kontrak"
                    label="Nilai Kontrak"
                    v-bind="money"
                    placeholder="Input Nilai Kontrak"
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol sm="6">
                  <CInput
                    v-model="nomor_kontrak"
                    label="Nomor Kontrak"
                    placeholder="Input nomor kontrak"
                  />
                </CCol>
                <CCol sm="6">
                  <CInput
                    v-model="tgl_kontrak"
                    label="Tgl Kontrak"
                    placeholder="Input tgl kontrak"
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol sm="6">
                  <CInput
                    v-model="tgl_mulai"
                    label="Tgl Mulai Pekerjaan"
                    placeholder="Input Tgl Mulai"
                  />
                </CCol>
                <CCol sm="6">
                  <CInput
                    v-model="tgl_selesai"
                    label="Tgl Selesai Pekerjaan"
                    placeholder="Input Tgl Selesai"
                  />
                </CCol>
              </CRow>

              <CRow>
                <CCol sm="6">
                  <CInput
                    v-model="panjang"
                    label="Panjang Penanganan"
                    placeholder="Input panjang"
                  />
                </CCol>
                <CCol sm="6">
                  <CInput
                    v-model="lebar"
                    label="Lebar"
                    placeholder="Input lebar"
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol sm="6">
                  <CInput
                    v-model="jumlah_hari_pelaksanaan"
                    label="Jumlah Hari Pelaksanaan"
                    placeholder="Input Jumlah Hari Pelaksanaan"
                  />
                </CCol>
                <CCol sm="6">
                  <CInput
                    v-model="pptk"
                    label="PPTK"
                    placeholder="Input PPTK"
                  />
                </CCol>
              </CRow>

              <CRow>
                <CCol sm="6">
                  <CInput
                    v-model="pengawas1"
                    label="Pengawas 1"
                    placeholder="Input pengawas 1"
                  />
                </CCol>
                <CCol sm="6">
                  <CInput
                    v-model="pengawas2"
                    label="Pengawas 2"
                    placeholder="Input pengawas 2"
                  />
                </CCol>
              </CRow>

                <div class="form-actions">
                  <CButton type="submit" color="primary" style='margin-right:15px'>Simpan</CButton>
                  <CButton color="secondary" to="/pekerjaan">Batal</CButton>
                </div>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CModal
      title="Error Input"
      color="danger"
      id="modalError"
    >
      <b>Error</b>! Data pekerjaan gagal diinput.
    </CModal>
    <CModal
      title="Pilih Koordinat"
      size="lg"
      id="mapModal"
      :show.sync="mapModal"
    >
      <img
        style="
          position: absolute;
          top: 50%;
          left: 50%;
          z-index: 10000;
          margin-left: -25px;
          margin-top: -50px;
          height: 50px;
          width: 50px;
          cursor: pointer;
        "
        :src="require('../../assets/images/marker.png')"
      />
      <input type="hidden" v-model="koordinat" />
      <gmap-map
        :zoom="14"
        :center="center"
        ref="mapRef"
        id="g-map"
        style="width: 100%; height: 300px"
      >
        <gmap-marker
          :key="index"
          v-for="(m, index) in locationMarkers"
          :position="m.position"
          @click="openMarker(m.id)"
        >
          <gmap-info-window
            :closeclick="true"
            @closeclick="openMarker(null)"
            :opened="openedMarkerID === m.id"
          >
            <div>{{ m.name }}</div>
          </gmap-info-window>
        </gmap-marker>
      </gmap-map>
    </CModal>
   
  </div>
    
  
</template>

<script>
import axios from 'axios';
import * as Modals from '../notifications/Modals.vue'
// import { CurrencyInput } from 'vue-currency-input'
import {mask} from 'vue-the-mask'
import {Money} from 'v-money'

export default {
  // components: {CurrencyInput },
  name: 'Forms',
  computed : {
    years () {
      const year = new Date().getFullYear()
      return Array.from({length: year - 2019}, (value, index) => new Date().getFullYear() - index)
    }
  },
  data () {
    return {
      center: {
        lat: 0.8851261,
        lng: 108.9814298,
      },
      money: {
          decimal: ',',
          thousands: '.',
          prefix: '',
          suffix: '',
          precision: 0,
          masked: true
      },
      mapModal: false,
      openedMarkerID: null,
      locationMarkers: [],
      locPlaces: [],
      existingPlace: null,
      tahun_anggaran : "",
      nama_kegiatan : "",
      sub_kegiatan : "",
      nama_pekerjaan : "",
      lokasi : "",
      kontraktor : "",
      konsultan_supervisi: "",
      nilai_kontrak: "",
      nilai_pagu: "",
      koordinat: "",
      panjang: "",
      lebar: "",
      nomor_kontrak: "",
      tgl_kontrak: "",
      tgl_mulai: "",
      tgl_selesai: "",
      jumlah_hari_pelaksanaan:"",
      pptk:"",
      pengawas1:"",
      pengawas2:"",
      map : null,
      selected: [], // Must be an array reference!
      show: true,
      horizontal: { label:'col-3', input:'col-9' },
      options: ['Option 1', 'Option 2', 'Option 3'],
      jenis: [],
      subjenis: [],
      tahuns: ['2021','2020'],
      selectOptions: [
        'Option 1', 'Option 2', 'Option 3',
        { 
          value: 'some value', 
          label: 'Selected option'
        }
      ],
      selectedOption: 'some value',

      formCollapsed: true,
      checkboxNames: ['Checkboxes', 'Inline Checkboxes',
                      'Checkboxes - custom', 'Inline Checkboxes - custom'],
      radioNames: ['Radios', 'Inline Radios',
                    'Radios - custom', 'Inline Radios - custom']
    }
  },
  mounted(){
    this.showPekerjaan();
    this.$refs.mapRef.$mapPromise.then((map) => {
          map.addListener("dragend", (e) => {
            // 3 seconds after the center of the map has changed, pan back to the
            // marker.
            // function call not working
            // alert( "Latitude: "+map.getCenter().lat()+" "+", longitude: "+map.getCenter().lng()); 
            // alert(e.latLng.lat()+","+ e.latLng.lng());
            this.koordinat = map.getCenter().lat()+","+ map.getCenter().lng();
          });
          // map.addListener(this.map, "dragend", (e) => {
          //   // function call not working
          //   alert("drag");
          //   this.koordinat = (e.latLng.lat(), e.latLng.lng());
          // });
        });
  },
  methods: {
    // validator (val) {
    //   return val ? val.length >= 4 : false
    // },
    updatePekerjaan: function(event){
      // Simple POST request with a JSON body using axios
      const pekerjaan = { 
                          id_rka:this.$route.params.id,
                          tahun_anggaran: this.tahun_anggaran,
                          nama_kegiatan: this.nama_kegiatan,
                          sub_kegiatan: this.sub_kegiatan,
                          nama_pekerjaan: this.nama_pekerjaan,
                          lokasi: this.lokasi,
                          koordinat: this.koordinat,
                          kontraktor: this.kontraktor,
                          konsultan_supervisi: this.konsultan_supervisi,
                          nilai_pagu: this.nilai_pagu,
                          nilai_kontrak: this.nilai_kontrak,
                          tgl_kontrak: this.tgl_kontrak,
                          tgl_mulai: this.tgl_mulai,
                          tgl_selesai: this.tgl_selesai,
                          panjang: this.panjang,
                          lebar: this.lebar,
                          jumlah_hari_pelaksanaan: this.jumlah_hari_pelaksanaan,
                          pptk: this.pptk,
                          pengawas1: this.pengawas1,
                          pengawas2: this.pengawas2,
                          };
      // alert(JSON.stringify(pekerjaan));
      axios.post("https://sippd.probowsolution.com/public/updatepekerjaan", pekerjaan)
        .then((response) => {
            // console.log(response);
            // alert(response.data);
            if(response.data=='sukses'){
              this.$router.push('/pekerjaan');
            }
            else{
              this.$root.$emit("bv::show::modal", "modalError");
            }
            
        })
    },
    showPekerjaan: function(event){
      // Simple POST request with a JSON body using axios
      // alert(JSON.stringify(pekerjaan));
      
      // alert(JSON.stringify(pekerjaan));
      axios.get("https://sippd.probowsolution.com/public/editpekerjaan/"+this.$route.params.id)
        .then((response) => {

              axios.get("https://sippd.probowsolution.com/public/kegiatan")
            .then((response3) => {

                  this.jenis = response3.data;
                
            });

          axios.get("https://sippd.probowsolution.com/public/subkegiatan")
            .then((response2) => {

                  this.subjenis = response2.data;
                
            });

              this.tahun_anggaran=response.data.tahun_anggaran;
              this.nama_pekerjaan=response.data.nama_pekerjaan;
              this.nama_kegiatan=response.data.nama;
              // alert(response.data.nama+" "+response.data.sub_kegiatan);
              this.sub_kegiatan=response.data.sub_kegiatan;
              this.lokasi=response.data.lokasi;
              this.kontraktor=response.data.nama_kontraktor;
              this.konsultan_supervisi=response.data.konsultan_supervisi;
              this.nilai_pagu=response.data.biaya_dpa;
              this.nilai_kontrak=response.data.nilai_kontrak;
              this.nomor_kontrak=response.data.nomor_kontrak;
              this.tgl_kontrak=response.data.tgl_kontrak;
              this.tgl_mulai=response.data.tgl_mulai;
              this.tgl_selesai=response.data.tgl_selesai;
              this.jumlah_hari_penanganan=response.data.jumlah_hari_penanganan;
              this.ppkt=response.data.ppkt;
              this.pengawas1=response.data.pengawas1;
              this.pengawas2=response.data.pengawas2;
              this.panjang=response.data.panjang;
              this.lebar=response.data.lebar;

              
              // alert(this.nama_kegiatan+"/"+this.sub_kegiatan);
            
        });

        
    }
  }
}
</script>
